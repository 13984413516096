<script>
    export default {
        name: 'Text',
        props: {
            text__alignment: String,
            text__orientation: String
        }
    }
</script>

<template>
    <section padding="medium">
        <div class="wrapper" wrapper="box">
            <div class="text" :orientation="text__orientation">
                <div class="text__body" :alignment="text__alignment" >
                    <slot name="text__body"></slot>
                </div>
            </div>
        </div>
    </section>
</template>