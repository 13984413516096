<script>
    import ImageComponent from '@/js/components/media/Image';

    export default {
        name: 'Footer',
        components: {
            ImageComponent
        }
    }
</script>

<template>
    <footer>
        <div class="wrapper" wrapper="box">
            <div class="footer__nav">
                <div class="footer__nav__brand">
                    <router-link to="/">
                        <image-component
                            :image__width="300"
                            :image__height="55"
                            :image__source="'/__system/breuer-klincevic_logo-white.svg'"
                            :image__alt="'Logo'"
                        ></image-component>
                    </router-link>
                </div>
                <nav class="footer__nav__menu">
                    <router-link to="/datenschutz" class="footer__nav__menu__item">
                        Datenschutz
                    </router-link>
                    <router-link to="/impressum" class="footer__nav__menu__item">
                        Impressum
                    </router-link>
                </nav>
            </div>
        </div>
    </footer>
</template>