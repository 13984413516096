<script>
    import ImageComponent from '@/js/components/media/Image';

    export default {
        name: 'Stat',
        components:{
            ImageComponent
        }
    }
</script>

<template>
    <section padding="medium" clip="rect--bottom--25">
        <div class="wrapper" wrapper="box">
            <div class="stats">
                <div class="stats__body">
                    <h6>
                        Stets für Sie im Einsatz
                    </h6>
                    <h2>
                        Mit jedem Kilometer wächst das Vertrauen
                    </h2>
                </div>
                <div class="stats__container">
                    <div class="stats__container__item">
                        <div class="stats__container__item__image">
                            <image-component
                                :image__source="'/content/home/home__stats--01.jpg'"
                                :image__alt="'Jahre im Einsatz'"
                            ></image-component>
                        </div>
                        <div class="stats__container__item__overlay">
                            <span>
                                26
                            </span>
                            <h6>
                                Jahre für Sie im Einsatz
                            </h6>
                        </div>
                    </div>
                    <div class="stats__container__item">
                        <div class="stats__container__item__image">
                            <image-component
                                :image__source="'/content/home/home__stats--02.jpg'"
                                :image__alt="'Gefahrene Kilometer'"
                            ></image-component>
                        </div>
                        <div class="stats__container__item__overlay">
                            <span>
                                500.000+
                            </span>
                            <h6>
                                Gefahrene Kilometer
                            </h6>
                        </div>
                    </div>
                    <div class="stats__container__item">
                        <div class="stats__container__item__image">
                            <image-component
                                :image__source="'/content/home/home__stats--03.jpg'"
                                :image__alt="'Fahrzeuge auf den Straßen'"
                            ></image-component>
                        </div>
                        <div class="stats__container__item__overlay">
                            <span>
                                10
                            </span>
                            <h6>
                                Fahrzeuge auf den Strassen
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>