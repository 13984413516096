<script>
    export default {
        name: "Image",
        props: {
            image__id: String,
            image__width: Number,
            image__height: Number,
            image__class: String,
            image__source: String,
            image__alt: String,
        }
    }
</script>

<template>
    <img
        :id="image__id"
        :width="image__width"
        :height="image__height"
        :class="image__class"
        :src="image__source"
        :srcset="`${image__source} 575w,
                ${image__source} 576w,
                ${image__source} 768w,
                ${image__source} 992w,
                ${image__source} 1200w,
                ${image__source} 1400w`"
        sizes=" (max-width: 575px) 575px,
                (min-width: 576px) 576px,
                (min-width: 768px) 768px,
                (min-width: 992px) 992px,
                (min-width: 1200px) 1200px,
                (min-width: 1400px) 1400px"
        :alt="image__alt"
        loading="lazy"
    >
</template>