<script>
    import ImageComponent from '@/js/components/media/Image';

    export default {
        name: 'Socialproof',
        components: {
            ImageComponent
        }
    }
</script>

<template>
    <section padding="medium" clip="rect--left--25">
        <div class="wrapper" wrapper="box">
            <div class="socialproof">
                <div class="socialproof__body">
                    <h6>
                        Unsere Kunden und Partner
                    </h6>
                    <h2>
                        Kunden und Partner,<br/>die uns vertrauen… 
                    </h2>
                    <p>
                        Wir sind unendlich dankbar für diese Vielzahl großartiger und namhafter Kunden und Partner, die uns ihr Vertrauen schenken und gemeinsam mit uns die Zukunft gestalten.
                    </p>
                </div>
                <div class="socialproof__container">
                    <div class="socialproof__container__item">
                        <image-component
                            :image__source="'/content/home/socialproof/_Müller.png'"
                            :image__alt="'Logo'"
                        ></image-component>
                    </div>
                    <div class="socialproof__container__item">
                        <image-component
                            :image__source="'/content/home/socialproof/_Vogelsang.png'"
                            :image__alt="'Logo'"
                        ></image-component>
                    </div>
                    <div class="socialproof__container__item">
                        <image-component
                            :image__source="'/content/home/socialproof/_Hampel.png'"
                            :image__alt="'Logo'"
                        ></image-component>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>