<script>
	import HeaderLayout from '@/js/layout/Header';
    import FooterLayout from '@/js/layout/Footer';
  
	export default {
		name: 'App',
		components: {
			HeaderLayout,
            FooterLayout
		}
  	}
</script>

<template>
	<header-layout></header-layout>
	<router-view/>
    <footer-layout></footer-layout>
</template>