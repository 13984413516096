<script>
    import HeroTemplate from '@/js/templates/Hero';
    import TextTemplate from '@/js/templates/Text';
    import ServiceTemplate from '@/js/templates/Service';
    import SocialproofTemplate from '@/js/templates/Socialproof';
    import StatsTemplate from '@/js/templates/Stat';
 
    export default {
        name: 'Home',
        components: {
            HeroTemplate,
            TextTemplate,
            ServiceTemplate,
            SocialproofTemplate,
            StatsTemplate
        }
    }
</script>

<template>
    <hero-template
        :hero__image="'/content/home/home__hero.jpg'"
    >
        <template #hero__body>
            <h1>
                Herzlich Willkommen bei Breuer und Klincevic 
            </h1>
            <router-link to="#leistungen" class="button button--primary">
                Unsere Leistungen
            </router-link>
        </template>
    </hero-template>
    <text-template
        :text__orientation="'left'"
        :text__alignment="'left'"
    >
        <template #text__body>
            <h6>
                Was wir Ihnen bieten
            </h6>
            <h2>
                Unsere Leistungen
            </h2>
            <p>
                Wir sind ein familiengeführtes Transportunternehmen mit verkehrsgünstigem Standort in Herne. Neben Transport mit LKW von Waren in loser und abgepackter Form bieten wir eine umfassende Beratung rundum das Thema Transport. 
            </p>
        </template>
    </text-template>
    <service-template></service-template>
    <socialproof-template></socialproof-template>
    <stats-template></stats-template>
</template>