<script>
    import ImageComponent from '@/js/components/media/Image';

    export default {
        name: 'Header',
        components: {
            ImageComponent
        },
        methods: {
            header__toggle(){
                $('#header__collapse').slideDown(400, function () {
                    $('#header__collapse').toggleClass('show')
                });
            }
        }
    }
</script>

<template>
    <header>
        <div class="header__nav">
            <div class="header__nav__brand">
                <router-link to="/">
                    <image-component
                        :image__width="300"
                        :image__height="55"
                        :image__source="'/__system/breuer-klincevic_logo-color.svg'"
                        :image__alt="'Logo'"
                    ></image-component>
                </router-link>
            </div>
            <div @click="header__toggle()" class="header__nav__toggle">
                <i class="fa-sharp fa-solid fa-bars"></i>
            </div>
            <nav class="header__nav__menu">
                <router-link to="/" class="header__nav__menu__item" active-class="active">
                    Start
                    <span></span>
                </router-link>
                <router-link to="/ueber-uns" class="header__nav__menu__item" active-class="active">
                    Über uns
                    <span></span>
                </router-link>
                <router-link to="/kontakt" class="header__nav__menu__item" active-class="active">
                    Kontakt
                    <span></span>
                </router-link>
                <router-link to="/jobs" class="header__nav__menu__item" active-class="active">
                    Jobs
                    <span></span>
                </router-link>
            </nav>
        </div>   
        <div id="header__collapse" class="header__collapse">
            <nav class="header__collapse__menu">
                <router-link to="/" @click="header__toggle()" class="header__collapse__menu__item" active-class="active">
                    Start
                </router-link>
                <router-link to="/ueber-uns" @click="header__toggle()" class="header__collapse__menu__item" active-class="active">
                    Über uns
                </router-link>
                <router-link to="/kontakt" @click="header__toggle()" class="header__collapse__menu__item" active-class="active">
                    Kontakt
                </router-link>
                <router-link to="/jobs" @click="header__toggle()" class="header__collapse__menu__item" active-class="active">
                    Jobs
                </router-link>
                <router-link to="/impressum" @click="header__toggle()" class="header__collapse__menu__item" active-class="active">
                    Impressum
                </router-link>
                <router-link to="/datenschutz" @click="header__toggle()" class="header__collapse__menu__item" active-class="active">
                    Datenschutz
                </router-link>
            </nav>
        </div>
    </header>
</template>