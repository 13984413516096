import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import VueAxios from 'vue-axios'

window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

try {
    window.$ = window.jQuery = require('jquery')
} catch (e) {
}


import '@/scss/app.scss'

const app = createApp(App)

app.use(router)
app.use(VueAxios, axios)

app.mount('#app')