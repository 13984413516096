<script>
    export default {
        name: "Scroll",
    }
</script>

<template>
    <div class="scroll">
        <div class="scroll__indicator">
            <i class="fa-duotone fa-chevrons-down"></i>
        </div>
    </div>
</template>