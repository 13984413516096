<script>
    import ImageComponent from '@/js/components/media/Image';

    export default {
        name: 'Service',
        components: {
            ImageComponent
        }
    }
</script>

<template>
    <section id="leistungen" padding="medium">
        <div class="wrapper" wrapper="fluid">
            <div class="service">
                <div class="service__item">
                    <div class="service__item__image">
                        <image-component
                            :image__source="'/content/home/home__transport.jpg'"
                            :image__alt="'Transport'"
                        ></image-component>
                    </div>
                    <div class="service__item__body">
                        <i class="fa-light fa-truck-fast fa-4x"></i>
                        <h3>
                            Transport
                        </h3>
                        <p>
                            Wir bieten verschiedenste Transportlösungen in unterschiedlichen Branchen und stets individuell auf Sie zugeschnitten.
                        </p>
                    </div>
                </div>
                <div class="service__item">
                    <div class="service__item__image">
                        <image-component
                            :image__source="'/content/home/home__beratung.jpg'"
                            :image__alt="'Beratung'"
                        ></image-component>
                    </div>
                    <div class="service__item__body">
                        <i class="fa-light fa-comments fa-4x"></i>
                        <h3>
                            Beratung
                        </h3>
                        <p>
                            Nicht sicher, wie wir helfen können? Fragen zum Ablauf? Wir bieten eine umfassende Beratung rundum das Thema Transport.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>