<script>
    import ScrollComponent from '@/js/components/content/Scroll';

    export default {
        name: 'Hero',
        components: {
            ScrollComponent
        },
        props: {
            hero__image: String
        }
    }
</script>

<template>
    <section padding="medium" static="static" class="background__image" :style="{'background-image': 'url(' + hero__image + ')'}">
        <div class="wrapper" wrapper="box">
            <div class="hero">
                <div class="hero__body">
                    <slot name="hero__body"></slot>
                </div>
            </div>
        </div>
        <scroll-component></scroll-component>
    </section>
</template>